import React from 'react';
import styled from 'styled-components';
import {Loader4} from '@styled-icons/remix-fill/Loader4';

export const SWIPE_STATE = {
  idle: 0,
  trans: 1,
  swipe: 2,
};

function ItemComp(props) {
  const {renderItem, item} = props;
  if (renderItem) {
    return renderItem(props);
  }
  return <SimpleItem {...props}>{JSON.stringify(item)}</SimpleItem>;
}

function Carousel(props) {
  const {
    width,
    height,
    data = [1, 2, 3],
    renderItem: _renderItem,
    renderPrev,
    renderNext,
    renderDots,
    renderPlaceholder,
    disableSwipe = false,
    autoSwipeDuration = 3000,
    pointCurrIdx = 0,
    style = {},
  } = props;
  const scrollViewRef = React.useRef();
  const swipeStateRef = React.useRef(SWIPE_STATE.idle);
  const swipeOffsetRef = React.useRef(null);
  const controllerRef = React.useRef();
  const dimension = {width, height};

  const goToIndex = React.useCallback(
    (idx) => {
      if (idx === data.length) {
        idx = 0;
      } else if (idx === -1) {
        idx = data.length - 1;
      } else {
        idx = Math.max(idx, 0);
        idx = Math.min(idx, data.length - 1);
      }

      const curr = scrollViewRef.current.scrollLeft;
      const target = idx * width;
      const steps = 10;
      const diff = (target - curr) / steps;
      let stepCnt = 0;

      function updateScrollLeft() {
        if (!scrollViewRef.current) {
          return;
        }

        stepCnt++;

        if (stepCnt === steps) {
          scrollViewRef.current.scrollLeft = target;
          swipeStateRef.current = SWIPE_STATE.idle;
          controllerRef.current.setCurrIdx(idx);
        } else if (stepCnt < steps) {
          scrollViewRef.current.scrollLeft += diff;
          requestAnimationFrame(updateScrollLeft);
        }
      }

      requestAnimationFrame(updateScrollLeft);
    },
    [data.length, width],
  );

  function onUserScrollStart() {
    swipeStateRef.current = SWIPE_STATE.trans;
  }

  function onUserScroll(clientX) {
    if (swipeStateRef.current > SWIPE_STATE.idle) {
      if (swipeStateRef.current === SWIPE_STATE.trans) {
        swipeStateRef.current = SWIPE_STATE.swipe;
      } else {
        let diff = clientX - swipeOffsetRef.current;
        scrollViewRef.current.scrollLeft -= diff;
        if (scrollViewRef.current.scrollLeft < 0) {
          scrollViewRef.current.scrollLeft = 0;
        }
      }

      swipeOffsetRef.current = clientX;
    }
  }

  function onUserScrollEnd() {
    const idx = Math.round(scrollViewRef.current.scrollLeft / width);
    goToIndex(idx);
  }

  const mouseEventHandlers = disableSwipe
    ? {}
    : {
        onMouseDown: onUserScrollStart,
        onMouseMove: (evt) => onUserScroll(evt.nativeEvent.clientX),
        onMouseUp: onUserScrollEnd,
        onMouseLeave: onUserScrollEnd,
        onTouchStart: onUserScrollStart,
        onTouchMove: (evt) => {
          const touch = evt.nativeEvent.touches[0];
          if (touch) {
            onUserScroll(touch.clientX);
          }
        },
        onTouchEnd: onUserScrollEnd,
      };

  if (dimension.width === undefined || dimension.height === undefined) {
    if (typeof renderPlaceholder === 'function') {
      return renderPlaceholder();
    } else if (renderPlaceholder !== false) {
      return (
        <Placeholder>
          <Loader4 size={60} color="white" className="spinner" />
        </Placeholder>
      );
    }
  }

  return (
    <Wrapper {...dimension} data={data} style={style}>
      <div className="placeholder" ref={scrollViewRef} {...mouseEventHandlers}>
        <div className="content">
          {data.map((item, idx) => {
            return (
              <div className="item" key={idx}>
                <ItemComp
                  renderItem={_renderItem}
                  item={item}
                  dimension={dimension}
                  swipeStateRef={swipeStateRef}
                />
              </div>
            );
          })}
        </div>
      </div>

      <Controller
        data={data}
        renderPrev={renderPrev}
        renderNext={renderNext}
        renderDots={renderDots}
        autoSwipeDuration={autoSwipeDuration}
        swipeStateRef={swipeStateRef}
        goToIndex={goToIndex}
        ref={controllerRef}
        pointCurrIdx={pointCurrIdx}
      />
    </Wrapper>
  );
}

function _Controller(props, ref) {
  const {
    data,
    renderPrev: _renderPrev,
    renderNext: _renderNext,
    renderDots: _renderDots,
    autoSwipeDuration,
    swipeStateRef,
    goToIndex,
    pointCurrIdx,
  } = props;
  let [currIdx, setCurrIdx] = React.useState(0);

  ref.current = {
    currIdx,
    setCurrIdx,
  };

  React.useEffect(() => {
    let timer = null;
    if (autoSwipeDuration > 0) {
      timer = setTimeout(() => {
        if (swipeStateRef.current === SWIPE_STATE.idle) {
          const nextIdx = currIdx + 1;
          goToIndex(nextIdx);
        }
      }, autoSwipeDuration);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [currIdx, goToIndex, autoSwipeDuration, swipeStateRef]);

  React.useEffect(() => {
    goToIndex(pointCurrIdx);
  }, [goToIndex, pointCurrIdx]);

  function goToNext() {
    goToIndex(currIdx + 1);
  }

  function goToPrev() {
    goToIndex(currIdx - 1);
  }

  function PrevComp(props) {
    if (_renderPrev) {
      return _renderPrev(props);
    } else if (_renderPrev === null) {
      return null;
    }
    return <SimplePrev onClick={goToPrev} {...props}>{`<`}</SimplePrev>;
  }

  function NextComp(props) {
    if (_renderNext) {
      return _renderNext(props);
    } else if (_renderNext === null) {
      return null;
    }
    return <SimpleNext onClick={goToNext} {...props}>{`>`}</SimpleNext>;
  }

  function Dots(props) {
    if (_renderDots) {
      return _renderDots(props);
    } else if (_renderDots === null) {
      return null;
    }
    return <SimpleDots>{`${currIdx + 1} / ${data.length}`}</SimpleDots>;
  }

  const commonProps = {currIdx, goToIndex};

  return (
    <>
      <NextComp {...commonProps} />
      <PrevComp {...commonProps} />
      <Dots {...commonProps} />
    </>
  );
}

const Controller = React.forwardRef(_Controller);

const Wrapper = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  & > .placeholder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    & > .content {
      width: ${(props) => props.width * props.data.length}px;
      display: flex;
      & > .item {
        flex: 0 0 ${(props) => props.width}px;
        height: ${(props) => props.height}px;
      }
    }
  }
`;

const SimpleItem = styled.div`
  width: ${(props) => props.dimension.width}px;
  height: ${(props) => props.dimension.height}px;
  background-color: pink;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SimpleNext = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  right: 0px;
  top: calc(50% - 16px);
  border: 1px solid black;
  background-color: white;
  user-select: none;
  cursor: pointer;
`;

const SimplePrev = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  left: 0px;
  top: calc(50% - 16px);
  border: 1px solid black;
  background-color: white;
  user-select: none;
  cursor: pointer;
`;

const SimpleDots = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
`;

const Placeholder = styled.div`
  width: 100vw;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;

  @keyframes infinite-spinning {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(360deg) scale(1.33);
    }
    100% {
      transform: rotate(720deg) scale(1);
    }
  }

  .spinner {
    animation: infinite-spinning 1.5s infinite;
  }
`;

export default Carousel;
